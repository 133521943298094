@font-face {
  font-family: 'shentox';
  src: url('../public/fonts/Shentox.ttf');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('../public/fonts/OpenSans-Bold.ttf');
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('../public/fonts/OpenSans-Light.ttf');
}
@font-face {
  font-family: 'OpenSansRegular';
  src: url('../public/fonts/OpenSans-Regular.ttf');
}
