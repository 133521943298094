.wrapper {
  @apply fixed flex justify-center items-center z-[19] inset-0 bg-black bg-opacity-70 backdrop-blur-md;
    .container {
      @apply fixed rounded-[16px] top-0 bottom-0  bg-[#121419]  my-[100px] flex justify-center items-center;
      .closeBtn {
        @apply absolute lg:w-[28px] md:w-[24px] w-[18px] lg:h-[28px] md:h-[24px] h-[18px] top-[21.5px] right-[40px] cursor-pointer z-[11];
      }
      .modalContent {
        @apply relative w-auto h-[90%]; 
      }
    }
  }
  