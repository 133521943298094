.wrapper {
  @apply absolute top-5 left-1/2 -translate-x-1/2 z-[100] cursor-default min-w-72;
  .listItem {
    @apply w-full flex justify-start rounded-lg transition hover:shadow hover:bg-secondary/10 hover:scale-105 ps-1 cursor-pointer;
  }
  .profile {
    @apply px-4
     pt-4 pb-3 flex gap-2 flex-col bg-[#1C1F26] rounded-b-[0] rounded-t-lg items-start justify-start;
    .profileInfo {
      @apply flex gap-4 justify-start items-center;
      .profilePic {
        @apply rounded-full w-[25px] h-[56px];
        svg {
          @apply flex w-full h-full items-center;
        }
      }
      .profileDetails {
        @apply flex flex-col items-start justify-start;
        .profileUsername {
          @apply font-semibold text-base text-white leading-5;
        }
        .profileReferral {
          @apply text-sm font-regular text-[#B2B2B2];
        }
      }
    }
    .wallet {
      @apply cursor-pointer flex gap-2 w-full justify-start items-center border border-[#29D7DF] rounded-[4px] px-4 py-2 mt-2 text-[#29D7DF] font-semibold text-[14px] hover:bg-[#0090A6] hover:text-white;
    }
    .walletConnected {
      @apply flex flex-row justify-around items-center gap-4 mt-2;
      h5 {
        @apply leading-3 text-sm text-white;
      }
      p {
        @apply text-left w-full;
      }
    }
  }
  ul {
    @apply bg-[#1C1F26] px-6 flex justify-start items-start flex-col pt-3 pb-6 rounded-t-[0] rounded-b-lg;
    li {
      @apply text-[#B2B2B2] font-regular text-[13px] py-1;
    }
  }
}
