.s40ToS24 {
  @apply text-[24px] md:text-[28px] lg:text-[32px] xl:text-[34px] 2xl:text-[40px];
}
.s36ToS22 {
  @apply text-[22px] md:text-[24px] lg:text-[28px] xl:text-[32px] 2xl:text-[36px];
}
.s32ToS20 {
  @apply text-[20px] md:text-[22px] lg:text-[26px] xl:text-[28px] 2xl:text-[32px];
}
.s28ToS18 {
  @apply text-[18px] md:text-[20px] lg:text-[22px] xl:text-[24px] 2xl:text-[28px];
}
.s28ToS15 {
  @apply text-[15px] md:text-[18px] lg:text-[18px] xl:text-[22px] 2xl:text-[28px];
}
.s26ToS16 {
  @apply text-[16px] md:text-[16px] lg:text-[18px] xl:text-[22px] 2xl:text-[26px];
}
.s24ToS16 {
  @apply text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] 2xl:text-[24px];
}
.s22ToS14 {
  @apply text-[14px] md:text-[14px] lg:text-[16px] xl:text-[20px] 2xl:text-[22px];
}
.s22ToS16 {
  @apply text-[16px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px];
}
.s20ToS16 {
  @apply text-[16px] md:text-[16px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px];
}
.s20ToS14 {
  @apply text-[14px] md:text-[16px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px];
}
.s18ToS14 {
  @apply text-[14px] md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[18px];
}
.s16ToS14{
  @apply text-[14px] md:text-[15px] lg:text-[15px] xl:text-[16px];
}
.s16ToS12{
  @apply text-[12px] md:text-[13px] lg:text-[14px] xl:text-[15px] 2xl:text-[16px];
}
.s14ToS12{
  @apply text-[12px] md:text-[12px] lg:text-[13px] xl:text-[14px] 2xl:text-[14px];
}
.s12ToS12{
  @apply text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[12px];
}

