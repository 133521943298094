.container {
  @apply lg:w-[850px] w-full px-0 pb-[25px] md:px-[50px] md:pb-[45px];
  .shadowContainer {
    @apply absolute right-0 left-0 top-0 h-[100%] w-[100%] overflow-hidden rounded-t-[0.25rem];
    .shadow {
      -webkit-filter: blur(100px);
      filter: blur(100px);
      @apply absolute top-0 right-0 left-0 mx-auto h-[250px] w-[500px] 
      -translate-y-[150px] rounded-[100%] bg-[#9FCB59] opacity-[46%];
    }
  }
  .title {
    @apply md:mt-[20px] mt-[20px] text-[#FBCE00] md:text-[40px] text-[28px] text-center uppercase mb-[18px] md:mb-[30px];
  }
  .desc {
    @apply md:text-[18px] text-[16px] text-white;
  }
}
