.root {
  @apply fixed bg-black w-screen h-screen z-20 flex flex-col justify-center bg-opacity-75 backdrop-blur-lg md:w-[450px] md:right-0;
  animation-name: root;
  animation-duration: 500ms;
  border-radius: 0% 0% 0% 0%;
  animation-timing-function: ease-out;
}

@keyframes root {
  0% {
    border-radius: 0% 0% 70% 0%;
    margin-left: 100%;
  }
  100% {
    margin-left: 0;
    border-radius: 0% 0% 0% 0%;
  }
}

.closeRoot {
  animation-name: closeRoot;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes closeRoot {
  0% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

.profile {
  @apply px-12 pb-6 flex gap-2 flex-col  rounded-b-[0] rounded-t-lg justify-center;
  .profileInfo {
    @apply flex gap-4 justify-start items-center -ml-8;
    .profilePic {
      @apply bg-[#20232B] rounded-full w-[40px] h-[40px];
      svg {
        @apply flex w-full h-full p-2 items-center;
      }
    }
    .profileDetails {
      @apply flex flex-col items-start justify-start;
      .profileUsername {
        @apply font-normal text-lg text-white leading-5;
      }
      .profileReferral {
        @apply text-sm font-regular text-[#B2B2B2];
      }
    }
  }
  .wallet {
    @apply flex gap-2 w-full justify-start items-center border border-[#29D7DF] rounded-[4px] px-4 py-2 mt-2 text-[#29D7DF] font-semibold text-[14px];
  }
  .walletConnected {
    @apply flex flex-row justify-start items-center mt-2;
    h5 {
      @apply leading-3 text-sm text-white text-left ml-3;
    }
    p {
      @apply text-left ml-3 w-full;
    }
  }
}

.marketplaceItem {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  color: white;
  gap: 8px;
  transition: background-color 0.3s ease;
}

.subLabel {
  font-weight: 500;
}

.subDesc {
  color: #686868;
}

.marketplaceItem:hover .subLabel,
.marketplaceItem:hover .subDesc {
  color: #28cfd7; /* Change to your desired hover color */
}
