.boxIcon {
  @apply cursor-pointer z-10;

  .boxMenu {
    @apply invisible relative opacity-0 translate-y-4 transition-all duration-300 ;
  }

  &:hover {
    .boxMenu {
      @apply visible opacity-100 translate-y-0 transition-all duration-300;
    }
  }
}
