.newFooterBox {
  // @apply w-full flex flex-col md:flex-row justify-between items-end md:items-start  xl:mt-48 lg:mt-24 relative ps-36 px-3 md:mx-0;
  @apply w-full max-w-[1920px] bg-black   relative lg:ps-24 xl:ps-44 px-8 lg:mx-0 xl:px-8 pb-[54px] ;

  // &::after {
  //   @apply w-[70%] h-[1px] bg-[#474951] absolute right-0 top-0;
  //   content: '';
  // }

  .addressContainer {
    @apply flex flex-col pt-10 lg:pt-14 pb-[80px] pr-[25px] lg:pr-[10px] 2xl:pr-[35px] gap-3 gap-x-[20px] md:gap-x-[30px] lg:gap-x-[100px] xl:gap-x-[50px] z-10 h-fit;
  }

  .addressTitle {
    @apply text-[#A0A1A3] lg:text-[18px] md:text-[16px] text-[14px];
  }
  .footerLinks {
    @apply pt-10 lg:pt-14 pb-10 md:pb-[200px] pr-[30px] lg:pr-[100px] grid grid-cols-[auto_auto_auto] gap-3 gap-x-[20px] md:gap-x-[30px] lg:gap-x-[100px] xl:gap-x-[50px] z-10 h-fit;

    li {
      a {
        @apply text-[#A0A1A3] lg:text-[18px] md:text-[16px] text-[14px] hover:text-white;
      }
    }
  }
}

.socialMediaContainer {
  // @apply flex gap-[10px] sm:gap-[20px] md:gap-[30px] lg:gap-[12px] xl:gap-3 items-center mt-2 bg-[#121419];
  @apply flex gap-1 mt-3;
  // left: 50%;
  // transform: translate(-50%, -50%);
  a {
    // @apply md:w-[25px] md:h-[25px] w-[18px] h-[18px];
    // &:hover {
    //   svg {
    //     @apply fill-white transition-all delay-75 ease-in-out;
    //     path {
    //       @apply fill-white transition-all delay-75 ease-in-out;
    //     }
    //   }
    // }
    svg {
      @apply fill-[#62666A];
      path {
        @apply fill-[#62666A];
      }
    }
    // &:last-child {
    //   path {
    //     @apply fill-gray-700;
    //   }
    // }
  }
}
.shadowContainer {
  @apply absolute right-0 left-0 top-0 z-10 h-[100%] w-[100%] overflow-hidden rounded-t-[0.25rem];
  .shadow {
    -webkit-filter: blur(20px);
    filter: blur(20px);
    @apply absolute top-0 right-0 left-0 mx-auto h-[200px] -translate-y-[140px] rounded-[100%] bg-[#29D7DF] opacity-[12%];
  }
}
