@mixin background($mainColor, $color1, $color2, $color3) {
  background: $mainColor;
  background: linear-gradient(90deg, $color1 0%, $color2 50%, $color3 100%);
}
@mixin baseButton($backgroundColor, $borderColor) {
  background-color: $backgroundColor;
  border-color: $borderColor;
  @apply w-[120px] h-[30px] sm:w-[150px] sm:h-[42px] md:w-[177px] md:h-[42px] rounded-[4px] sm:rounded-[8px] border-[2px] text-[16px] sm:text-[18px] md:text-[20px] flex gap-2 text-[white];
}
@mixin baseCircle($backgroundColor) {
  background-color: $backgroundColor;
  @apply w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] md:w-[69px] md:h-[69px] rounded-[69px] flex items-center justify-center text-[#FFFFFF] font-bold md:text-[30px]  text-[18px];
}

.modalSubTitle {
  @apply mt-[24px] md:px-[80px] text-center font-bold text-[#FFFFFF] md:text-[22px] lg:text-[20px] text-[18px];
}
.stepCard {
  @apply w-full h-[123px] rounded-[17px] flex items-center py-[16px] md:py-[27px] justify-between mt-[17px] md:px-[29px] px-[15px] flex-wrap
  min-w-[300px] sm:min-w-[500px] md:min-w-[550px] lg:min-w-[700px];
}
.loginCard {
  @include background(
    $mainColor: rgb(35, 59, 30),
    $color1: rgba(35, 59, 30, 1),
    $color2: rgba(36, 44, 43, 1),
    $color3: rgba(35, 38, 46, 1)
  );
}
.connectWalletCard {
  @include background(
    $mainColor: rgb(34, 61, 62),
    $color1: rgba(34, 61, 62, 1),
    $color2: rgba(36, 43, 50, 1),
    $color3: rgba(35, 38, 46, 1)
  );
}
.disabledCard {
  background: rgb(43, 45, 53);
  background: linear-gradient(90deg, rgba(43, 45, 53, 1) 29%, rgba(35, 38, 45, 1) 61%);
}
.loginVariant {
  @include baseButton(#233c30, #29df41);
}
.connectWalletVariant {
  @include baseButton(#263d45, #29d7df);
}
.disabled {
  @include baseButton(#2e3138, #808080);
  @apply text-[#808080];
}
.stepContainer {
  @apply flex gap-[12px] sm:gap-[20px] md:gap-[26px] items-center;
}
.loginCircle {
  @include baseCircle(#23561b);
}
.connectWalletCircle {
  @include baseCircle(#234c4f);
}
.disabledCircle {
  @include baseCircle(#3f4147);
}
.stepTextContainer {
  @apply flex flex-col;
  .stepTitle {
    @apply text-[22px];
  }
  .stepDesc {
    @apply font-bold lg:text-[24px] sm:text-[18px] text-[14px] text-[#FFFFFF];
  }
  .stepDescDisabled {
    @extend .stepDesc;
    @apply text-[#808080];
  }
}
