.wrapper {
  @apply inline-flex align-middle relative;
  .ball {
    @apply flex-grow inline-block w-[20px] h-[20px] rounded-[20px];
    background: gray;
    animation: zoom 0.5s infinite linear alternate;
    transform: scale(0.5, 0.5);
    opacity: 0.4;
    &:nth-child(odd) {
      animation-delay: 0.5s;
    }
  }
  @keyframes zoom {
    from {
      transform: 'scale(0.4, 0.4)';
      opacity: 0.6;
    }
    to {
      transform: 'scale(1, 1)';
      opacity: 1;
    }
  }
}
