.base {
  @apply cursor-pointer min-w-[100px]  flex items-center justify-center;
  &_secondart {
    @apply cursor-pointer bg-red-400 w-[100px] h-[50px] flex items-center justify-center rounded;
  }
  &_disabled {
    @apply cursor-pointer text-[#585A61] border-[2px] border-[#585A61] bg-transparent;
  }
  &_Primary {
    @apply cursor-pointer rounded-[8px] border-[#585A61] bg-[#44F8FF] w-[323px] h-[50px] text-[#20232B] text-[23px] font-bold;
  }
}
.temp {
  @apply  border-red-900 w-[20%] border;
}
.temp2 {
  @apply border-red-800 text-red-200 border w-[200px] h-[200px] outline-error outline flex items-center justify-center;
}
