.title {
  @apply text-[#E8C944] md:text-[40px] text-[30px] text-center uppercase mt-[25px] md:mt-[0px];
}
.desc {
  @apply text-white md:text-[28px] text-[18px] md:w-[513px] w-auto text-center mx-auto;
}
.planetContainer {
  @apply flex flex-col items-center justify-center mt-[10%] lg:w-[70%] md:w-[90%] mx-auto;
  .image {
    @apply w-[230px] h-[230px] relative rounded-[8px];
  }
  .name {
    @apply text-white md:text-[28px] text-[20px] mt-[4%];
  }
}

.okButton {
  @apply h-[48px] w-[140px] lg:w-[220px] md:w-[200px] lg:h-[54px] rounded-[8px] border-[2px] border-white bg-[#3B3E45] text-[20px] font-bold text-[white] md:text-[22px] lg:text-[23px]  mt-[5%] mx-[3%] md:mx-[2%];
}
.viewButton {
  @apply h-[48px] w-[140px] lg:w-[220px] md:w-[200px] lg:h-[54px] rounded-[8px] border-[2px] border-[#29DF41] bg-[#213A2E] text-[20px] font-bold text-[white] md:text-[22px] lg:text-[23px]  mt-[5%];
}

.contentContainer {
  @apply lg:px-5 overflow-visible mt-[3%] lg:w-[920px] xl:h-[670px] h-auto w-full pb-[5%];
  .modalTitle {
    @apply text-center text-[22px] font-bold uppercase text-[#E8C944] md:text-[32px] lg:text-[40px] flex-col flex leading-[40px];
  }
  .modalSubTitle {
    @apply pr-[0px] pl-[0px] text-center text-[12px] font-bold text-[#FFFFFF] md:pr-[100px] md:pl-[100px] md:text-[22px] lg:text-[26px];
  }
}
.shadow {
  filter: blur(100px);
  @apply absolute top-0 right-0 left-0 mx-auto h-[250px] w-[500px] -translate-y-[150px] rounded-[100%] bg-[#9FCB59] opacity-[46%];
}
.shadowContainer {
  @apply absolute right-0 left-0 top-0 z-0 h-[100%] w-[100%] overflow-hidden rounded-t-[0.25rem];
}
