.contentContainer {
  @apply flex h-auto w-full flex-col items-center overflow-visible md:px-5 lg:px-[110px];

  .shadowContainer {
    @apply absolute left-0 right-0 top-0 z-10 h-full w-full overflow-hidden rounded-t-[16px];

    .shadow {
      @apply absolute left-0 right-0 top-0 mx-auto h-[150px] w-[15%]  -translate-y-[90px] rounded-[100%] bg-[#b8acac] blur-[50px] md:w-[30%] md:blur-[100px] lg:w-[15%] lg:blur-[110px];
    }
  }

  .badgeDescContainer {
    @apply relative z-20 flex w-full flex-col items-center md:mt-[32px] md:max-w-none;

    .modalTitle {
      @apply max-w-[228px] text-center text-[22px] font-bold uppercase leading-[29px] text-white md:max-w-none md:whitespace-nowrap md:text-[30px];
    }

    .promotionContainer {
      @apply mt-[12px] flex flex-col items-center md:mt-[24px];

      .textItem {
        @apply max-w-[324px] text-center text-[18px] leading-[28px] text-[#B2B2B2] md:max-w-[600px] lg:max-w-[700px] md:text-[22px] lg:leading-[32px];
      }
    }

    .form {
      @apply mb-[54px] mt-[30px] grid grid-cols-1 items-center justify-center gap-x-[10px] gap-y-[4px] md:mb-[87px] md:mt-[59px] md:grid-cols-[220px_auto] md:gap-y-[15px] lg:mb-[138px] lg:mt-[78px];

      .label {
        @apply text-[18px] text-[#B2B2B2] md:text-[23px] text-nowrap;
      }

      .inputContainer {
        @apply mb-[15px] flex w-full items-center gap-[18px] rounded-[8px] border-2 border-solid border-[#31333B] px-[3px] py-[2px] md:mb-0 md:px-[3px] md:py-[3px] lg:mx-10 md:mx-8 mx-0;

        .input {
          input {
            @apply border-transparent bg-transparent text-[21px] text-[#118288] outline-transparent;
          }
        }

        .button {
          @apply ml-auto flex h-[34px] w-[70px] items-center justify-center rounded-[4px] bg-[#31333B] text-[14px] text-[#B2B2B2] md:h-[46px] md:w-[124px] lg:rounded-[8px] lg:text-[19px];
        }
      }

      .inputContainer:last-child {
        @apply mb-0;
      }
    }
  }
}
