.header {
  @apply bg-[#1C1F26] backdrop-blur-md fixed top-0 right-0 w-full z-20 flex flex-col 
  items-center justify-center min-h-[60px] md:min-h-[70px] xl:min-h-[80px];
}
.boxHeader {
  max-width: 1920px;

  @apply w-full flex flex-row items-center justify-between px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 bg-[#1C1F26];
}

.boxImageLogo {
  @apply flex items-center;

  svg {
    pointer-events: none;
  }
}
.btns {
  @apply flex lg:pl-0 pl-2 gap-[8px] sm:gap-[11px] items-center;
  .showCase {
    @apply z-[1] relative cursor-pointer font-normal text-[#B2B2B2] lg:flex hidden items-center justify-center capitalize;
    span {
      svg {
        @apply w-[35px] inline-block;
      }
    }
  }
  .buttonConnectGoogle,
  .buttonConnectWallet {
    @apply z-[1] relative cursor-pointer font-bold flex items-center justify-center w-[24px] h-[24px];
    // p {
    //   @apply items-center flex px-2 whitespace-nowrap lg:text-[17px] md:block hidden;
    // }
    // span {
    // transition: all 0.5s;
    // @apply cursor-pointer border;
    .walletSvg {
      svg {
        @apply hover:fill-[#E0E1E1];
        path {
          @apply hover:fill-[#E0E1E1];
        }
      }
    }
    // }
    // span {
    //   @apply inline-block mr-2;
    // }
  }
  // .buttonConnectWallet::before {
  //   position: absolute;
  //   content: '';
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   // background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, transparent 60%, transparent 100%);
  //   z-index: -1;
  //   transition: opacity 0.5s linear;
  //   opacity: 0;
  // }
  // .buttonConnectWallet:hover::before {
  //   opacity: 1;
  // }

  .buttonConnectGoogle::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, transparent 60%, transparent 100%);
    z-index: -1;
    transition: opacity 0.5s linear;
    opacity: 0;
  }
  .buttonConnectGoogle:hover::before {
    opacity: 1;
  }

  .modal {
    @apply absolute top-full left-1/2 bg-white rounded-lg text-black p-4;
    .loginBtn {
      @apply flex flex-col;
    }
    button {
      @apply px-4 py-2 border border-solid border-gray-500 rounded-lg my-2;
    }
  }
}

.wrapper {
  @apply absolute top-1/2 left-1/2 rounded-lg bg-white;
  transform: translate(10%, 100%);
  .btns {
    @apply flex flex-col justify-center my-4 text-black p-8;

    button,
    a {
      @apply p-4 my-4 border border-solid border-gray-100 rounded-lg font-bold text-xl text-left;
      span {
        @apply text-left p-2 even:-ml-8;
        img {
          @apply inline-block mr-2 even:mr-4;
        }
      }
    }
  }
}
.userBtn {
  @apply absolute mt-16 right-[10px] justify-center items-center bg-white text-black text-xl font-bold px-11 text-center py-4 rounded-lg;
}

.listItem {
  @apply block p-2 hover:text-[#29d7df]  border-b-[1px];
}

.dropDownIcon {
  @apply inline mr-2 mb-1;
}

.listItem:hover .dropDownIcon {
  @apply fill-[#29d7df];
}

.buttonConnectWalletTwo {
  transition: all 0.5s;
  @apply relative sm:me-0 flex gap-2 items-center rounded-md
   text-white hover:fill-white bg-transparent h-14 justify-center;
  span {
    transition: all 0.5s;
    @apply cursor-pointer;
    svg {
      @apply hover:fill-[#E0E1E1];
      path {
        @apply hover:fill-[#E0E1E1];
      }
    }
  }
}
.signup,
.login {
  transition: all 0.5s;
  @apply text-white h-8 lg:h-10 justify-center items-center flex hover:text-[#B2B2B2] bg-[#1F212A] px-2 md:px-4 py-[4px] rounded-[4px] md:rounded-lg border border-white hover:border-[#ffffff49] font-medium;
}

.baseBtn {
  @apply overflow-hidden relative flex font-semibold px-2 xl:px-4 py-1 xl:py-[6px] cursor-pointer;
}

.btnLRT {
  @extend .baseBtn;
  @apply text-[#29D6DF] h-8 lg:h-10 border-[#29D6DF] border rounded-[4px] md:rounded-lg justify-center  py-[4px]  
  items-center flex gap-3 w-auto min-w-[90px] pb-[6px] xl:min-w-[110px];

  // &:after {
  //   background: #fff;
  //   content: '';
  //   height: 177px;
  //   left: -75px;
  //   opacity: 0.3;
  //   position: absolute;
  //   top: -50px;
  //   transform: rotate(35deg);
  //   width: 18px;
  //   z-index: 0;
  //   animation: slideIn 1.5s linear infinite;
  // }
  // .btnLRTChild {
  //   @apply rounded-[4px] md:rounded-lg;
  // }
}

.miningGameBtn {
  @extend .baseBtn;
  @apply min-w-[85px] p-[1px] md:p-[2px] bg-[#4B4628] text-[#F6D21F] sm:text-[15px] text-[12px] hidden lg:flex;

  .bgShadow {
    @apply w-full h-full py-2 px-3 rounded-full flex items-center text-nowrap;
  }
  .boxPlaySvg {
    @apply w-[25px] h-[25px] rounded-full hidden md:flex mr-2;
    padding: 3px 2px 3px 3px;
    // border: 2px solid white;

    svg {
      path {
        fill: #f6d21f;
      }
    }
  }

  svg {
    @apply z-[2];
  }
}
.btnTaskStream {
  @extend .baseBtn;
  @apply min-w-[75px] p-[2px];
  .btnTaskStreamChild {
    @apply sm:w-[160px] h-full bg-[#2E4F22] lg:rounded-[17px] rounded-full flex justify-center items-center sm:text-[15px] text-[12px];
  }
}
.circleRed {
  animation: pulse 1s infinite;
  @apply w-[7px] h-[7px] rounded-full bg-[#FF0000] mr-2;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideIn {
  0% {
    left: -75px;
  }
  100% {
    left: 120%;
  }
}

.buttonConnectWalletTwo:hover {
  position: relative;
  span {
    svg {
      fill: white;
      path {
        transition: all 0.5s;
        fill: white;
      }
    }
  }
  .walletAddresText {
    margin-left: 12px;
    transition: all 0.5s;
    color: white;
  }
}

.lineHeader {
  @apply w-[1px] h-[14px] bg-[#4A4D54] mx-1 sm:mx-4;
}
.lampMotion {
  @apply relative w-[7px] h-[7px] bg-[#DFBC17] mt-5 -mr-1  z-[3000] rounded-full;
  -webkit-animation: opacity linear infinite 1.5s;
  animation: opacity linear infinite 1.5s;
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@keyframes opacity {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.link {
  @apply text-white hover:text-[#cacfd0] transition-all;
}
.active {
  @apply text-[#29D6DF] hover:text-[#cacfd0];
}

.linkContainer {
  position: relative;
}

.popover {
  @apply min-w-[470px] bg-[#1C1F26] xl:min-w-[530px] 2xl:min-w-[600px]  rounded-lg top-[35px] xl:top-[50px];
  position: absolute;
  left: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px;
  height: fit-content;
  width: fit-content;
}

.subLink {
  @apply line-clamp-1 mx-2;
  display: block;
  padding: 5px 10px;
  color: white;
  text-decoration: none;
  min-width: 200px;
}

.subLink:hover {
  @apply bg-[#777477] rounded-md p-2;
}

.marketplace {
  @apply grid grid-cols-2 gap-2;
  // padding: 20px;
  border-radius: 10px;
  color: white;
}

.marketplaceItem {
  display: flex;
  align-items: start;
  padding: 6px;
  border-radius: 8px;
  color: white;
  gap: 8px;
  transition: background-color 0.3s ease;
}

.marketplaceItem:hover {
  background-color: #151414;
}

.subLabel {
  font-weight: 500;
}

.subDesc {
  color: #686868;
}
