.wrapper {
  @apply relative bg-transparent min-w-[258px] min-h-[256px] md:min-w-[466px] md:min-h-[320px] lg:min-w-[600px] lg:min-h-[371px];
  .headerCloseButton {
    @apply absolute z-20 flex h-[50px] w-full flex-col items-end;
    .closeButton {
      @apply flex h-[31px] w-[31px] shrink-0 items-center justify-center rounded-full border-2 border-white p-[7px] text-2xl cursor-pointer text-white;
    }
  }
  .modalContent {
    @apply flex flex-col items-center gap-[30px];
  }

  .title {
    @apply text-[26px] md:text-[38px] lg:text-[47px] font-bold mt-[10px] lg:mt-[20px];
  }

  .description {
    @apply text-[#B2B2B2] text-[14px] md:text-[16px] lg:text-[20px] text-center px-4 min-h-[80px] md:min-h-[100px] lg:md:min-h-[120px] py-[3%];
  }
  .buttonContainer {
    @apply flex gap-[10px] lg:gap-[20px];
  }
  .button {
    @apply relative focus:outline-none h-[40px] md:h-[56px] md:min-w-[180px] lg:h-[62px] lg:min-w-[230px] border rounded-[4px] md:rounded-[8px] text-[#fff] font-bold text-[14px] md:text-[18px] xl:text-[20px] px-4 min-w-[116px];
  }
}
