.container {
  @apply flex max-w-[1000px] flex-col px-5;
}
.header {
  @apply relative z-20 flex h-[50px] w-full flex-col items-end;
  .closeButton {
    @apply flex h-[31px] w-[31px] shrink-0 items-center justify-center rounded-full border-2 border-white p-[7px] text-2xl text-white;
  }
}
.contentContainer {
  @apply h-auto lg:px-5 overflow-visible pb-12;
}
.shadowContainer {
  @apply absolute right-0 left-0 top-0 z-10 h-[100%] w-[100%] overflow-hidden rounded-t-[0.25rem];
}
.shadow {
  -webkit-filter: blur(100px);
  filter: blur(100px);
  @apply absolute top-0 right-0 left-0 mx-auto h-[250px] w-[500px] -translate-y-[150px] rounded-[100%] bg-[#9FCB59] opacity-[46%];
}
.badgeDescContainer {
  @apply relative z-20 flex flex-col;

  .tableStyle {
    @apply w-full text-left text-white;

    thead {
      th {
        @apply last:pr-2 text-center bg-[#30322F] text-[15px] md:text-[18px] lg:text-[22px] font-regular leading-[33px] text-[#D9CE6A] first:rounded-l-md last:rounded-r-md;
      }
    }

    tbody {
      td {
        @apply text-center text-[16px] md:text-[20px] lg:text-[22px] lg:first:leading-[58px] lg:first:text-[24px] h-[50px] leading-[21px] md:leading-[48px] first:text-left first:font-bold first:w-fit last:w-fit md:first:w-4/12;
      }

      tr {
        @apply last:font-bold last:border-t-[1px] last:border-[#707070] first:border-b-[1px] first:border-[#707070];
      }
    }
  }
}

.BadgeContainer {
  @apply absolute top-0 right-0 left-0 z-20 mx-auto h-auto w-[111px] -translate-y-[30px] md:w-[180px] md:-translate-y-[85px] lg:w-[260px] xl:w-[200px] xl:-translate-y-[60px];
}
.ClubeHeaderBg {
  @apply absolute -top-[90px] right-0 left-0 z-[15] mx-auto h-[170px] w-[305px] md:top-0 md:w-[556px] md:-translate-y-[150px] lg:w-[827px] lg:-translate-y-[250px] xl:-translate-y-[300px];
}
.modalTitle {
  @apply text-center text-[22px] font-bold uppercase text-[#E8C944] md:text-[32px] lg:text-[40px];
}

.modalSubTitle {
  @apply mt-[24px] pr-[0px] pl-[0px] text-center text-[18px] font-bold text-[#FFFFFF] md:pr-[100px] md:pl-[100px] md:text-[22px] lg:text-[26px];
}

.scrollable {
  @apply relative overflow-y-auto flex-grow md:overflow-auto px-1;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 12px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px solid #414141;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #414141;
  }
}

@media only screen and (max-width: 499px) and (max-height: 748px) {
  .scrollable {
    @apply max-h-[200px];
  }
}

@media only screen and (min-width: 500px) and (max-height: 640px) {
  .scrollable {
    @apply max-h-[200px];
  }
}

@media only screen and (max-height: 742px) {
  .scrollable {
    @apply max-h-[200px];
  }
}

.promotionContainer {
  @apply mt-[24px] flex flex-col pr-[25px] pl-[25px];
}
.nextStepContainer {
  @apply mt-[24px] flex flex-col pr-[25px] pl-[25px];
}
.nextStep {
  @apply mb-[9px] text-[24px] font-bold text-[#FFFFFF];
}
.textItem {
  @apply text-[15px] text-[#B2B2B2] md:text-[20px]  lg:text-[22px];
}
.buttonCotainer {
  @apply mt-[35px] mb-[20px] flex w-full justify-center px-[50px] md:mb-[30px] md:px-0;
}
.button {
  background: linear-gradient(rgba(41, 223, 65, 0.12), rgba(21, 112, 33, 0.12));
  @apply h-[48px] w-[250px] lg:w-[417px] lg:h-[56px] rounded-[8px] border-[2px] border-[#29DF41] bg-transparent text-[20px] font-bold text-[white] md:text-[22px] lg:text-[23px];
}
.CongratulationTitle {
  @apply mt-[10px] text-center text-[18px] font-bold text-[white] md:mt-[47px] md:text-[26px];
}
.CongratulationDesc {
  @apply mb-[58px] mt-[34px] pr-[0px] pl-[0px] text-center text-[15px]  leading-[28px] text-[#B2B2B2] md:mb-[105px] md:mt-0 md:pr-[100px] md:pl-[100px] md:text-[22px] md:leading-normal;
}

.bodyContainer {
  @apply md:px-[50px] md:mt-[48px] px-[0px];
}
