.root {
  @apply flex w-full justify-center max-w-[1920px] pb-[50px];
}

.rootSecondaryStyle {
  @apply overflow-hidden lg:overflow-visible;
}
.layoutContentSecondaryStyle {
  @apply md:pt-[56px] pt-[44px];
}

.boxMenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  border-radius: 10px;

  background-color: #1d2027;
}

.boxMenu::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: #4a4d54;
}

.boxMenu::-webkit-scrollbar {
  width: 2px;
}

.confirmBtn {
  @apply mt-5 flex w-full items-center justify-center p-[1px] pb-4 lg:mt-5;
  .boxCraft {
    @apply relative mt-9 flex h-[40px] w-[270px] cursor-pointer items-center justify-center bg-[#1affff] p-[1px] md:h-[56px] xs:h-[50px];
    &::before {
      content: '';
      @apply absolute -top-1 right-[-1px] z-[3] w-0 border-t-[20px] border-l-[20px]  border-t-[#121419] border-l-transparent md:border-t-[30px] md:border-l-[30px];
    }
  }
  .boxCraftTwo {
    @apply relative flex h-full w-full items-center justify-center bg-[#121419] p-[4px] text-xl font-bold text-[#20232B];
    &::before {
      content: '';
      @apply absolute top-0 right-0 z-[2]  w-0 border-t-[15px] border-l-[15px] border-t-[#1affff] border-l-transparent md:border-t-[25px] md:border-l-[25px];
    }
  }
  .boxCraftThree {
    @apply relative flex h-full w-full items-center justify-center bg-[#1affff] text-xl font-bold text-[#20232B];
    &::before {
      content: '';
      @apply absolute top-[-1px] right-[-1px] w-0 border-t-[15px] border-l-[15px] border-t-[#121419] border-l-transparent md:border-t-[25px] md:border-l-[25px];
    }
  }
}

.rootWide {
  @apply mx-4 sm:mx-7 md:mx-10 lg:mx-16 xl:mx-20;
}

.rootCompact {
  @apply mx-4 md:mx-16 lg:mx-24 xl:mx-44 2xl:mx-56;
}
