@import './reset.css';
@import './fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
  background-color: #121419;
  color: #ffffff;
}

#wcm-modal {
  z-index: 100;
}

a {
  color: inherit;
  text-decoration: none;
}
.active-link {
  &:hover {
    p {
      color: #29d7df !important;
    }

    path {
      fill: #29d7df !important;
    }
    g {
      fill: #29d7df !important;
    }
  }
  p {
    color: #29d7df !important;
  }

  path {
    fill: #29d7df !important;
  }
  g {
    fill: #29d7df !important;
  }
  // border-right: 1px solid #29d7df;
}
.box-item-menu {
  @apply last:mb-[0px];
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

textarea:focus,
input:focus {
  outline: none;
}
.hamburger-react {
  &:nth-child(2) {
    div {
      width: 14px !important;
    }
  }
}
.tab {
  margin-top: 4rem;
  font-size: 22px;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;
  border-bottom: 4px solid #fff;
}
.tab-active {
  color: #29d7df;
}
.swiper-pagination-bullet {
  background: #fff !important;
}
.boxMegaMenuItem {
  a {
    @apply flex cursor-pointer items-center;
  }

  span {
    @apply text-lg font-bold capitalize italic;
  }
  div {
    @apply flex items-center justify-center;
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
}
.megaMenuHover {
  a {
    @apply flex cursor-pointer items-center;
  }

  span {
    @apply text-lg font-bold capitalize italic;
  }
  div {
    @apply flex items-center justify-center;
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
  &:hover {
    span {
      color: #29d7df;
    }
    svg {
      path,
      rect {
        fill: #29d7df !important;
      }
    }
  }
}
.service {
  width: 315px;
}
@media (max-width: 768px) {
  .service__dropdown-indicator,
  .service__input-container,
  .service__single-value {
    width: unset !important;
    margin-left: unset !important;
  }
  .service__menu-list {
    width: unset !important;
    margin-left: unset !important;
  }
  .service__option {
    width: 445px;
    margin-left: unset !important;
  }
  .service__menu {
    width: unset !important;
    margin-left: -3rem;
  }
}

.hamburger-react[aria-expanded='false'] div:nth-child(2) div {
  width: 12px !important;
}
.hamburger-react[aria-expanded='false'] div:nth-child(3) div {
  width: 18px !important;
}
@media screen and (max-width: 992px) {
  .title-partners {
    font-size: 30px;
  }
  .line-partners {
    width: 250px;
  }
}

@media screen and (max-width: 768px) {
  .title-partners {
    font-size: 20px;
    white-space: nowrap;
  }
  .line-partners {
    width: 150px;
  }
}
[data-rmiz-modal-overlay='visible'] {
  background: transparent !important;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
}

.text-feature-item-active {
  transition: all 1s;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #b2b2b2 !important;
}
.partners {
  @apply w-full max-w-full;
  .swiper-slide-duplicate {
    display: none !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 70%;
    top: 24% !important;
    display: flex;
    align-items: center;
    // border-radius: 18px;
    // border: 2px solid #353946;
    &::after {
      margin-left: 10px;
    }
  }
  .swiper-button-next {
    &::after {
      content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6'><path fillRule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
      width: 30px;
      margin-top: 3em;
    }
  }
  .swiper-button-prev {
    &::after {
      content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6' ><path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z' /></svg>");
      width: 30px;
      margin-top: 3em;
    }
  }
}

.promotionData {
  .swiper-pagination {
    @apply absolute top-[12.7rem];
  }
}

.phase-sale-swiper {
  .swiper-button-prev,
  .swiper-button-next {
    transform: scale(2.5);
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    z-index: 1;
    align-items: center;
    border-radius: 100%;
    background-color: transparent;
  }
  .swiper-button-next {
    &::after {
      padding: 7px;
      width: 30px;
      height: 30px;
      content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-50 h-50'><path fillRule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }
  .swiper-button-prev {
    left: 0;
    &::after {
      position: relative;
      width: 30px;
      height: 30px;
      padding: 7px;
      content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-50 h-50' ><path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z' /></svg>");
    }
  }
}

.mining-slider {
  .swiper-button-prev,
  .swiper-button-next {
    position: relative;
    width: 30px;
    height: 30px;
    align-items: center;
    border-radius: 100%;
    background-color: #37393e;
    border: 2px solid #37393e;
  }
  .swiper-button-next {
    @apply mx-[93%] -mt-[30px] xl:mx-[97%];
    &::after {
      padding: 7px;
      width: 30px;
      height: 30px;
      content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6'><path fillRule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }
  .swiper-button-prev {
    @apply my-0 mx-[75%] md:mx-[83%] xl:mx-[89%] 2xl:mx-[91%];
    &::after {
      position: relative;
      width: 30px;
      height: 30px;
      padding: 7px;
      content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6' ><path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z' /></svg>");
    }
  }
}
.material-slider {
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    top: 40px;
    z-index: 0;
    @media (max-width: 600px) {
      top: 30px;
      width: 25px;
      height: 25px;
    }
    align-items: center;
    border-radius: 100%;
    background-color: #37393e;
    border: 2px solid #37393e;
  }
  .swiper-button-next {
    &::after {
      padding: 7px;
      width: 30px;
      height: 30px;
      content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6'><path fillRule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }
  .swiper-button-prev {
    right: 50px;
    left: auto;
    &::after {
      position: relative;
      width: 30px;
      height: 30px;
      padding: 7px;
      content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6' ><path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z' /></svg>");
    }
  }
}
.shakeBtn:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}
.tankfull {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
  animation-iteration-count: infinite;
}
.walletConnect {
  animation: shake 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) alternate-reverse;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
  animation-iteration-count: infinite;
}
.walletTextColorChange {
  animation-name: walletTextColorChange;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes walletTextColorChange {
  from {
    color: white;
  }
  to {
    color: #29d7df;
  }
}
.shiny {
  overflow: hidden;
  &::before {
    animation: shiny 3s linear infinite;
    position: absolute;
    content: '';
    display: inline-block;
    height: 90%;
    background: linear-gradient(45deg, #8eff6d, #2fcc00);
    width: 15px;
    top: 0;
    left: 0;
  }
}
@keyframes shiny {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(0);
    opacity: 0.3;
  }
  60% {
    -webkit-transform: scale(0);
    opacity: 0.6;
  }
  61% {
    -webkit-transform: scale(4);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50);
    opacity: 0;
  }
}
.plus {
  animation: heartbeat 2s infinite;
}
@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
.moveWallet {
  animation: lefttoright 3s infinite linear;
}
@keyframes lefttoright {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
.onBoarding-slider {
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 80%;
    width: 117px;
    height: 80px;
    align-items: center;
  }
  .swiper-button-next {
    background-image: url('/images/onBoarding/next.svg');
    background-repeat: no-repeat;
    background-size: contain;
    left: 85%;
    &::after {
      padding: 7px;
      content: '';
    }
  }
  .swiper-button-prev {
    background-image: url('/images/onBoarding/prev.svg');
    left: 73%;
    background-repeat: no-repeat;
    background-size: contain;
    &::after {
      padding: 7px;
      content: '';
    }
  }
}
.on-boarding-btn {
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}
.on-boarding-mobile {
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 90%;
    width: 117px;
    height: 40px;
    align-items: center;
  }
  .swiper-button-next {
    background-image: url('/images/onBoarding/next.svg');
    background-repeat: no-repeat;
    background-size: contain;
    left: 55%;
    &::after {
      padding: 7px;
      content: '';
    }
  }
  .swiper-button-prev {
    background-image: url('/images/onBoarding/prev.svg');
    left: 27%;
    background-repeat: no-repeat;
    background-size: contain;
    &::after {
      padding: 7px;
      content: '';
    }
  }
}
.earnMore-slider {
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    top: 50%;
    z-index: 25;
    align-items: center;
    border-radius: 100%;
    background-color: #1a3f43;
    border: 2px solid #1a3f43;
  }
  .swiper-button-next {
    &::after {
      padding: 7px;
      width: 30px;
      height: 30px;
      content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6'><path fillRule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }
  .swiper-button-prev {
    left: 10px;
    &::after {
      position: relative;
      width: 30px;
      height: 30px;
      padding: 7px;
      content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6' ><path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z' /></svg>");
    }
  }
}

#earnMoreSwiper {
  .swiper-wrapper {
    width: 300px;
  }
  .swiper-pagination {
    bottom: 0px;
  }
}

#salePhase {
  .swiper-pagination {
    bottom: 5%;
  }
}

.home-slider {
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply right-3 bottom-20 z-20 flex h-[27px] rounded-full bg-black bg-opacity-50 px-3 md:right-10 lg:bottom-40;
    align-items: center;
    left: auto;
    width: auto;
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      @apply h-2 w-6 rounded-lg bg-white;
    }
  }
}
.builder-slider {
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply right-10 bottom-28 left-auto z-20 flex hidden h-[27px] w-auto rounded-full bg-black bg-opacity-50 px-3 sm:block md:right-20 lg:bottom-40;
    align-items: center;
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      @apply h-2 w-6 rounded-lg bg-white;
    }
  }
}
.builder-pagination {
  .swiper-pagination {
    display: none !important;
  }
}
.inventory-slider {
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    top: 100px;
    z-index: 3;
    @media (max-width: 600px) {
      top: 30px;
      width: 25px;
      height: 25px;
    }
    align-items: center;
    border-radius: 100%;
    background-color: #37393e;
    border: 2px solid #37393e;
  }
  .swiper-button-next {
    &::after {
      padding: 7px;
      width: 30px;
      height: 30px;
      content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6'><path fillRule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }
  .swiper-button-prev {
    right: 50px;
    left: auto;
    &::after {
      position: relative;
      width: 30px;
      height: 30px;
      padding: 7px;
      content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6' ><path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z' /></svg>");
    }
  }
  .swiper-pagination {
    @apply absolute -bottom-[29px] h-9 w-9;
  }
}
.mirror {
  transform: scaleX(-1);
}
.scroll {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    border-radius: 10px;

    background-color: #1d2027;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: #4a4d54;
  }
  &::-webkit-scrollbar {
    width: 2px;
  }
}
.buy-element {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  place-items: center;
  padding-bottom: 2rem;
}
.forget-password-btn {
  margin-top: 0 !important;
}
.mobile-number-wrapper {
  @apply mt-6 w-full bg-transparent;
  .country-list {
    .country,
    .highlight {
      background-color: #121419 !important;

      &:hover {
        background-color: #121419 !important;
        color: #fff !important;
        font-size: 18px !important;
        font-weight: bold;
      }
    }
  }
}
.mobile-number-inner {
  background-color: transparent !important;
  color: #fff !important;
  font-size: 24px !important;
  font-weight: bold;
  width: 100% !important;
}
.mobile-number-dropdown {
  position: relative;
  overflow-x: hidden;
  background-color: #121419 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: bold;
  white-space: nowrap;
  width: 350px !important;
}
.mobile-number-search {
  background-color: #121419 !important;
  color: #000 !important;
  font-size: 24px !important;
  font-weight: bold;
}

.mantine-Slider-bar {
  @apply bg-[#29D7DF];
}
.mantine-Slider-thumb {
  @apply border-[#29D7DF];
}

// // customized scrollthumb
// ::-webkit-scrollbar {
//   width: 5px !important;               /* width of the entire scrollbar */
// }

// ::-webkit-scrollbar-track {
//   background: gray !important;        /* color of the tracking area */
// }

// ::-webkit-scrollbar-thumb {
//   background-color: #f5f5f5 !important;    /* color of the scroll thumb */
//   border-radius: 20px !important;       /* roundness of the scroll thumb */
//   border: 3px solid #333 !important;  /* creates padding around scroll thumb */
// }

.youtube_player_prev .youtube_player_next {
  position: absolute;
  z-index: 10;
  color: red;
  font-weight: 700;
  font-size: 40px;
}

.youtube_video_player_list {
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    top: 50%;
    z-index: 25;
    align-items: center;
    border-radius: 100%;
  }
  .swiper-button-next {
    &::after {
      padding: 7px;
      width: 30px;
      height: 30px;
      content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6'><path fillRule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }
  .swiper-button-prev {
    left: 10px;
    &::after {
      position: relative;
      width: 30px;
      height: 30px;
      padding: 7px;
      content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' stroke='white' class='w-6 h-6' ><path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z' /></svg>");
    }
  }
}
.mantine-Accordion-chevron {
  display: none !important ;
}

.header_notifier_badge {
  @apply h-[9px] w-[9px] bg-red-500 rounded-full;
}
// check before merge this class remove focus-visible of Welcome modal in latets firefox update
@-moz-document url-prefix() {
  .mantine-Paper-root {
    &:focus-visible {
      outline: none !important;
    }
  }
}

.fixed-tasks-confirm-section {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: 4rem;
}
.button-hover-effect {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.button-hover-effect:hover {
  transform: translateY(-7px);
  opacity: 0.8;
}
