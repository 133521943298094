.skeleton {
  & > div {
    &:after {
      background-color: rgb(24, 24, 24);
    }
    &:before {
      background-color: rgb(39, 39, 39);
    }
  }
}
