.wrapper {
  @apply relative w-auto h-full flex flex-col mt-[30px] px-[30px] lg:min-w-[900px] md:max-w-[692px] md:min-w-[692px] sm:min-w-[499px] max-w-[499px];
}
.textWrapper {
  @apply flex flex-col leading-[21px] px-2;
  .headTitle {
    @apply h-[40px] font-bold text-[22px] md:text-[36px] text-white uppercase;
  }
  .subTitle {
    @apply h-[21px] relative text-[16px] font-normal text-[#B2B2B2];
  }

  .findmeWrapper {
    @apply w-full flex justify-end items-center text-[18px] font-semibold gap-[14px] tracking-wide mb-1 pr-2;
    input[type='checkbox'] {
      @apply relative w-[18px] h-[18px] rounded-[4px] bg-[#2A3625] cursor-pointer appearance-none;
    }

    input[type='checkbox']::before {
      @apply absolute top-0 left-0 block bg-[#2A3625] w-full h-full rounded-[inherit];
      content: '';
    }

    input[type='checkbox']:checked::before {
      @apply text-[16px] leading-[18px] text-yellow-700 text-center;
      content: '\2713';
    }
  }
}
