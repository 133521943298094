//   globals
$bRadius: 6px;
.leftRadius {
  border-top-left-radius: $bRadius;
  border-bottom-left-radius: $bRadius;
}
.rightRadius {
  border-top-right-radius: $bRadius;
  border-bottom-right-radius: $bRadius;
}
.paragraphAndDivierContainer {
  @apply text-center;
  div {
    @apply flex justify-between items-center;
  }
  p {
    @apply mx-[20px] md:mx-5 lg:mx-10 w-full;
  }
}
.divider {
  @apply w-[1px] h-[30px] bg-[#20232B];
}

.hiddenBlocksInMobile {
  @media (max-width: 767px) {
    // Disable some classnames here for mobile breakpoint
    .bodyAchievements,
    .bodyTask,
    .headAchievements,
    .headTasks {
      display: none;
    }
  }
}

.container {
  @apply relative mx-auto w-full md:min-w-[80%] flex flex-col;

  ::-webkit-scrollbar {
    @apply w-[5px] h-[5px];
  }
  ::-webkit-scrollbar-track {
    @apply bg-[#707070] rounded-[16px];
  }
  ::-webkit-scrollbar-thumb {
    @apply bg-[white] rounded-[16px];
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(184, 184, 184);
  }

  table {
    @apply relative  h-auto w-full overflow-clip  transition-all;
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  th {
    @apply h-[56px] text-[16px] font-semibold cursor-default;
    &:first-child {
      @extend .leftRadius;
    }
    &:last-child {
      @extend .rightRadius;
    }
  }
  td {
    @apply cursor-default;
    &:first-child {
      @extend .leftRadius;
    }
    &:last-child {
      @extend .rightRadius;
    }
  }
  thead {
    @apply sticky top-0  md:text-[20px] font-extralight text-[#FFF] capitalize bg-[#353945] z-[1];
    @extend .paragraphAndDivierContainer;
    tr {
      @extend .hiddenBlocksInMobile;
      th {
        @apply truncate;
      }
    }
  }
  tbody {
    @apply relative md:text-[22px];

    tr,
    th {
      @apply hover:bg-gray-600 hover:bg-opacity-10 hover:backdrop-blur-sm cursor-pointer;
    }
    .userText {
      @apply text-[#B2B2B2];
    }
    .achievementsDoneText,
    .taskText {
      @apply md:text-[24px] font-light;
    }
    .totalPointText {
      @apply md:text-[24px] font-normal text-[#65C93B];
    }

    @extend .hiddenBlocksInMobile;
  }
}
